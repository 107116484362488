import React from 'react'
import Meta from '../components/Meta'
import { PageNotFound } from '../components/PageNotFound'

const NotFoundPage = () => (
	<>
		<Meta pageTitle="404" />
    <PageNotFound />
	</>
)

export default NotFoundPage

